<template>
  <BaseTable class="payment-table">
    <template #header>
      <th class="th-sku">{{ $t('listSKU') }}</th>
      <th class="th-name">{{ $t('modelProductName') }}</th>
      <th class="th-name"></th>
      <th class="th-qty">{{ $t('paymentTable.qty') }}</th>
      <th class="th-const-per-item">{{ $t('cost') }} {{$t('perItem')}}</th>
      <th class="th-cost">{{ $t('cost') }}</th>
      <th class="th-arrow"></th>
    </template>

    <template #body>
      <tr v-for="item in payments" :key="item.sku">
        <td>{{ item.sku }}</td>
        <td colspan="2"><span class="name">{{ item.name }}</span></td>
        <td>{{ item.quantity }}</td>
        <td><span>${{ item.price }}</span></td>
        <td><span>${{ item.sum }}</span></td>
      </tr>
    </template>
  </BaseTable>
</template>

<script>
import BaseTable from '@/components/ui/tables/BaseTable'

export default {
  name: 'PaymentTable',
  components: {
    BaseTable,
  },
  props: ['payments'],
}
</script>

<style scoped lang="scss">
$gutter: 20px;

.payment-table {
  border: none;
  table-layout: fixed;

  &:hover {
    background-color: #fff !important;
  }

  tbody tr {
    &:hover {
      background: #fff !important;
    }
  }

  th {
    border: 1px solid $color-line;
    border-left: none;
    border-right: none;
    padding-left: 0;
    padding-right: $gutter;

    &.th-sku {
      width: 180px;
    }

    &.th-name {
      width: 240px;
      box-sizing: border-box;
    }

    &.th-cost {
      width: 100px;
      padding-left: $gutter;
      padding-right: 0;
      box-sizing: border-box;
    }

    &.th-arrow {
      width: 40px;
      box-sizing: border-box;
    }
  }

  td {
    border: none;
    padding: 20px 20px 20px 0;

    &:last-child {
      padding-left: $gutter;
      padding-right: 0;
      box-sizing: border-box;
    }

    .name {
      color: $text-gray;
    }
  }

   tr, thead, tbody {
    border-left: none !important;
    border-right: none !important;
     border-color: transparent !important;

     &:hover {
       background-color: #fff !important;
     }
  }
}

strong {
  font-weight: bold;
}
</style>
