<template>
  <PageModalWrapper v-model="modal" @input="close">
    <template #content>
      <div class="payment" v-if="payment && modal">

        <header class="payment-info">
          <div class="payment-info__name">{{$t('payment')}} <span class="primary-color">{{ payment.paymentNumber }}</span></div>
          <div class="payment-info__total"><span>{{$t('total')}}: </span> <strong>${{ payment.totalSum }}</strong></div>
        </header>
        <hr>

        <h3 class="payment-title"><strong>{{$t('orderList')}}</strong> ({{ payment.orders.length }})</h3>

        <BaseTable class="payment-table">
          <template #header>
            <th class="th-shipment">{{ $t('paymentTable.shipment') }}</th>
            <th class="th-track">{{ $t('paymentTable.track') }}</th>
            <!--th class="th-marketplace">{{ $t('paymentTable.marketplace') }}</th-->
            <th class="th-qty">{{ $t('paymentTable.qty') }}</th>
            <th class="th-total">{{ $t('paymentTable.total') }}</th>
            <th class="th-arrow"></th>
          </template>
          <template #body>
            <template v-for="(order) in payment.orders" :key="order.paymentNumber">
              <tr
                @click="showItems(order)"
                class="order"
                :class="{checked: order.showItems}">
                <td class="table-shipment">
                  <p>{{order.orderNumber}}</p>
                  <p class="date">{{ order.createdAt }}</p>
                </td>
                <td class="table-track"><span>{{ order.trackNumber }}</span></td>
                <!--td class="table-marketplace">
                  <BrandLogo
                    style="display: block;"
                    :name="order.marketplace.name.toLowerCase()"
                  />
                </td-->
                <td class="table-quantity">
                  <p>{{ order.items.length }}&nbsp;<span>{{order.items.length > 1 ? 'items' : 'item'}}</span></p>
                </td>
                <td class="table-total">
                  <strong>${{ order.orderSum }}</strong>
                </td>

                <td class="order-arrow">
                  <slot name="icon">
                    <svg-icon
                      class-name="order-arrow-icon"
                      :class="{ open: order.showItems }"
                      icon-name="expand-arrow"
                    />
                  </slot>
                </td>
              </tr>
              <TableExpansionPanel
                :is-show="order.showItems" col-span="5"
              >
                <br>
                <h3 class="payment-title">
                  <strong>{{$t('itemList')}} </strong>
                  <span>({{ order.items.length }})</span>
                </h3>
                <PaymentTable :payments="order.items" />
              </TableExpansionPanel>
            </template>
          </template>
        </BaseTable>

        <br>

        <footer class="payment-info">
          <span></span>
          <div class="payment-info__total"><span>{{$t('total')}}: </span> <strong> ${{ payment.totalSum }}</strong></div>
        </footer>
      </div>
    </template>
  </PageModalWrapper>
</template>

<script>
import BaseTable from '@/components/ui/tables/BaseTable'
import BrandLogo from '@/components/pages/billing/BrandLogo'
import PaymentTable from '@/components/pages/billing/PaymentTable'
import PageModalWrapper from '@/components/ui/modals/PageModalWrapper'
import TableExpansionPanel from '@/components/ui/expansion/TableExpansionPanel'
import { mapState } from 'vuex'

export default {
  name: 'BillingPayment',
  components: {
    TableExpansionPanel,
    PaymentTable,
    PageModalWrapper,
    BrandLogo,
    BaseTable,
  },
  data () {
    return {
      modal: false,
    }
  },
  computed: {
    ...mapState({
      payment: state => state.billing.payment,
    }),
  },
  methods: {
    async getData () {
      this.$store.commit('setLoadingStatus')
      const id = this.$route.params.id
      await this.$store.dispatch('getPayment', { id })
      this.modal = true
      this.$store.commit('setSuccessStatus')
    },
    close () {
      setTimeout(() => {
        this.$router.push('/billing')
      }, 200)
    },
    showItems (order) {
      this.$store.dispatch('showPaymentOrder', { order })
    },
    show (element) {
      element.style.height = '0'
      element.style.opacity = '0'
      element.style.fontSize = '0'
      element.style.padding = '0'
    },

    hide (element) {
      element.style.transition = '0.3s ease all'
      element.style.fontSize = null
      element.style.padding = null
      element.style.height = `${element.scrollHeight}px`
      element.style.opacity = '1'
    },
  },
  created () {
    setTimeout(() => {
      this.getData()
    }, 100)
  },
}
</script>

<style lang="scss" scoped>
$gutter: 20px;

.payment {
  &-title {
    padding-bottom: $gutter;
    color: $text-gray;

    strong {
      color: #000;
    }
  }

  &-table {
    border-collapse: inherit;
    table-layout: fixed;

    td {
      padding: 20px;
      vertical-align: middle;
    }

    th {
      box-sizing: border-box;

      &.th-shipment {
        width: calc(220px - #{$gutter});
      }

      &.th-track,
      &.th-marketplace {
        width: 240px;
      }

      &.th-total {
        width: 100px;
      }

      &.th-arrow {
        width: 60px;
      }
    }

    tbody tr {
      box-sizing: border-box;

      &:hover {
        background-color: #fff;
      }
    }
  }

  &-info {
    @include flex(row, space-between);
    width: 100%;

    &__total {
      @include flex(row, flex-end);

      span {
        white-space: break-spaces;
      }

      strong {
        font-weight: 500;
      }
    }
  }

  .order {
    cursor: pointer;
    position: relative;

    &-arrow {
      &-icon {
        transition: .2s ease all;
        fill: transparent;
        &.open {
          transform: rotate(180deg);
        }
      }
    }

    &.checked {
      td {
        border-bottom: none;
      }
    }
  }

  hr {
    margin: $gutter 0;
    border: 1px solid #E5E5E5;
  }

  tr {
    &.checked {
      border: none;
      padding: 20px;

      td {
        border-top: 1px solid $primary-color;

        &:first-child {
          border-left: 1px solid $primary-color;
        }

        &:last-child {
          border-right: 1px solid $primary-color;
        }
      }
    }
  }
}

.table {
  &-shipment {
    .date {
      color: $text-gray;
      padding-top: 4px;
    }
  }

  &-quantity {
    color: $text-gray;
  }
}

strong {
  font-weight: bold;
}

.primary-color {
  color: $primary-color;
}
</style>
