<template>
  <tr class="expand-tr"
      :class="{checked: isShow}">

    <td :colspan="colSpan" class="expand-td">
      <transition
        @before-enter="show"
        @enter="hide"
        @before-leave="hide"
        @leave="show"
      >
        <div v-if="isShow" class="expand-inner">
          <slot></slot>
        </div>
      </transition>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'TableExpansionPanel',
  props: {
    isShow: {
      type: Boolean,
      default: false,
      require: true,
    },
    colSpan: {
      type: String,
      default: '1',
      require: true,
    },
  },
  methods: {
    show (element) {
      element.style.height = '0'
      element.style.opacity = '0'
      element.style.fontSize = '0'
      element.style.padding = '0'
    },

    hide (element) {
      element.style.transition = '0.3s ease all'
      element.style.fontSize = null
      element.style.padding = null
      element.style.height = `${element.scrollHeight}px`
      element.style.opacity = '1'
    },
  },
}
</script>

<style lang="scss" scoped>
$gutter: 20px;

.expand-tr {
  padding: 0;

  &.checked {
    td {
      border: 1px solid $primary-color;
      border-top: none;
    }
  }
}

.expand-td {
  padding: 0;
}

.expand-inner {
  margin-left: $gutter;
  margin-right: $gutter;
  padding-top: $gutter;
  transition: .3s ease height;
  overflow: hidden;
  box-sizing: border-box;
  border-top: 1px solid $color-line;
}
</style>
